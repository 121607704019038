import React, { useEffect, useState } from 'react';
import * as styles from './footer.module.scss';
import { graphql, Link, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

const Footer = ( { className = '' } ) => {

    const data = useStaticQuery(
        graphql`
            query footerContactQuery {
                strapiPageConfig {
                    kontakt
                  }
            }
        `
    )

    const [trustpilotLoaded, setTrustpilotLoaded] = useState(false);

    useEffect(() => {
        let attempts = 0;
        const maxAttempts = 20;
        const delay = 250;
        let timeoutId;

        const initTrustpilot = () => {
            if (typeof window !== 'undefined' && window.Trustpilot) {
                const widget = document.querySelector('.trustpilot-widget');
                if (widget) {
                    window.Trustpilot.loadFromElement(widget);
                    setTrustpilotLoaded(true);
                    return true;
                }
            }
            return false;
        };

        const retryInit = () => {
            if (attempts >= maxAttempts) {
                console.warn('Failed to initialize Trustpilot widget after', maxAttempts, 'attempts');
                return;
            }

            if (!initTrustpilot()) {
                attempts++;
                timeoutId = setTimeout(retryInit, delay);
            }
        };

        // Reset attempts on each mount/navigation
        attempts = 0;
        setTrustpilotLoaded(false);
        retryInit();

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            attempts = maxAttempts; // Stop retrying if component unmounts
        };
    }, []); // Empty dependency array means this runs once when component mounts

    return <div className={className + " " + styles.footer}>

        <div className="container">

            <div className="row">
                <div className="col-12">
                    <div className={styles.contact}>
                        <h4>Kontakt</h4>
                        <ReactMarkdown allowDangerousHtml={true} children={data.strapiPageConfig.kontakt} />
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5">
                <div className="col-12 ">
                    <div 
                        className="trustpilot-widget" 
                        data-locale="de-DE" 
                        data-template-id="56278e9abfbbba0bdcd568bc" 
                        data-businessunit-id="6501c53dd197e77f8bc4a64f" 
                        data-style-height="52px" 
                        data-style-width="100%"
                        key={trustpilotLoaded ? "trustpilot-loaded" : "trustpilot-loading"}
                    >
                        <a href="https://de.trustpilot.com/review/golfsport.at" target="_blank" rel="noopener">Trustpilot</a>
                    </div>
                </div>
            </div>

            <div className="row mt-5">

                <div className="col-12 col-md-6 col-xl-3">
                    <h4>Mein Konto</h4>

                    <ul>
                        {/*<li>*/}
                        {/*    <Link to={"/konto"}>Mein Konto</Link>*/}
                        {/*</li>*/}

                        <li>
                            <Link to={"/warenkorb"}>Warenkorb</Link>
                        </li>

                        <li>
                            <Link to={"/konto/bestellungen"}>Meine Bestellungen</Link>
                        </li>
                    </ul>
                </div>

                <div className="col-12 col-md-6 col-xl-3 mt-5 mt-md-0">
                    <h4>Hilfe & Service</h4>

                    <ul>
                        <li>
                            <Link to={"/static/unternehmen"}>Unternehmen</Link>
                        </li>

                        <li>
                            <Link to={"/static/standorte"}>Standorte</Link>
                        </li>

                        <li>
                            <Link to={"/static/oeffnungszeiten"}>Öffnungszeiten</Link>
                        </li>

                        <li>
                            <Link to={"/static/zahlung-und-versand"}>Zahlung und Versand</Link>
                        </li>
                    </ul>
                </div>

                <div className="col-12 col-md-6 col-xl-3 mt-5 mt-md-0">
                    <h4>Rechte & Fristen</h4>

                    <ul>
                        <li>
                            <Link to={"/static/agb"}>Allgemeine Geschäftsbedigungen</Link>
                        </li>

                        <li>
                            <Link to={"/static/datenschutzerklaerung"}>Datenschutz (DSGVO)</Link>
                        </li>

                        <li>
                            <Link to={"/static/widerrufsrecht"}>Widerrufsrecht</Link>
                        </li>

                        <li>
                            <Link to={"/static/impressum"}>Impressum</Link>
                        </li>
                    </ul>
                </div>


            </div>
        </div>

    </div>

};

export default Footer;
